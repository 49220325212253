import React, { ReactElement } from "react";

// Table
import MaterialTable from "material-table";

// MUI
import { Button, LinearProgress, Typography } from "@material-ui/core";

// Hooks
import { useHistory, useParams } from "react-router";

// Util
import { sortBy } from "lodash";

// Components
import IAQBuildingDialog from "./IAQBuildingDialog";

interface Props {
	clients: any;
	buildings: any;
}

export default function IAQClientTable({
	clients,
	buildings,
}: Props): ReactElement | null {
	const history = useHistory();
	const params: any = useParams();

	let tableData: any = sortBy(Object.values(Object.assign(clients, {})), [
		"name",
	]);

	const building = buildings[params.buildingId];

	if (params.buildingId && Boolean(!building)) {
		return <LinearProgress />;
	}

	return (
		<div style={{ width: "100%" }}>
			<MaterialTable
				columns={[
					{ title: "Client", field: "name" },
					{
						title: "Sites",
						field: "sites",
						render: (data: any) => (
							<Typography variant="button">{data.sites.length}</Typography>
						),
					},
					{
						title: "Buildings",
						field: "",
						render: (data: any) => {
							return (
								<div style={{ display: "flex", flexWrap: "wrap" }}>
									{data.sites.map((site: any) =>
										sortBy(site.buildings, ["name"]).map((building: any) => (
											<div>
												<Button
													size="small"
													variant="outlined"
													color="primary"
													style={{ marginRight: "1rem" }}
													onClick={() => history.push(`/iaq/${building.id}`)}
												>
													{building.name}
												</Button>
											</div>
										))
									)}
								</div>
							);
						},
					},
				]}
				data={tableData}
				title="IAQ"
				options={{
					actionsColumnIndex: -1,
					tableLayout: "auto",
				}}
			/>

			{/* Building Dialog */}
			<IAQBuildingDialog building={building} open={Boolean(building)} />
		</div>
	);
}
