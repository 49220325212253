import React, { useState } from "react";

// Components
import IntroLoader from "./components/IntroLoader/IntroLoader";
import Login from "./pages/Login/Login";

// MUI
import {
	Button,
	createMuiTheme,
	createStyles,
	makeStyles,
	MuiThemeProvider,
	Theme,
} from "@material-ui/core";

import { indigo } from "@material-ui/core/colors";

// Hooks
import { useAppSelector } from "./app/hooks";

// Redux
import { RootState, store } from "./app/store";
import { fetchClients } from "./features/clients/clientsSlice";

// Firebase
import { useFirebase } from "react-redux-firebase";

// Router
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";

// Pages
import { Home } from "./pages/Navigation/Home";

// Static
import Abstract1 from "./static/backgrounds/industrial-3.jpg";

// Providers
import { SnackbarProvider } from "notistack";

// Components
import { DashboardAppBar } from "./components/AppBars/DashboardAppBar/DashboardAppBar";
import { Spaces } from "./pages/Navigation/Spaces";
import { Space } from "./pages/Space/Space";
import { DeviceScreen } from "./pages/Device/DeviceScreen";
import { IAQPublicPage } from "./pages/IAQ/IAQPublicPage";

import { setAutoFreeze } from "immer";
import { useWindowSize } from "./hooks/useWindowSize";
import Profile from "./pages/Profile/Profile";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		app: {
			width: "100%",
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundAttachment: "fixed",
		},
	})
);

function App() {
	setAutoFreeze(false);
	// DB
	const firebaseHook = useFirebase();

	// Styles
	const classes = useStyles();
	const size = useWindowSize();

	// Store
	const auth = useAppSelector((state: RootState) => state.firebase.auth);
	const clients = useAppSelector((state: RootState) => state.clients);
	const profile = useAppSelector((state: RootState) => state.firebase.profile);

	// Action dispatch
	const dispatch = store.dispatch;

	// State
	const [error, setError] = useState({ title: "", message: "" });
	const [whitelabel, setWhitelabel] = React.useState({
		enabled: false,
		logoURL: "",
		primary: "",
		secondary: "",
		padding: "",
	});

	const { primary, secondary } = whitelabel;

	// Handles initial authentication
	// Convenience
	const init = Boolean(auth.isLoaded && profile.isLoaded);

	React.useEffect(() => {
		// If auth has been initialized, an API key is present, and a client fetch has yet to be attempted
		if (init && profile?.api && clients?.status === "") {
			dispatch(fetchClients({ api: profile.api, type: "private" }));
		}

		// Handle failure to fetch
		if (clients?.status === "failed") {
			setError({
				title: "Clients fetch failed",
				message: `An ${clients?.error} error that caused a failure to fetch your clients. Please retry.`,
			});
		}

		// Remove error if fetch succeeds
		if (clients?.status === "succeeded") {
			setError({ title: "", message: "" });
		}

		// no need to refresh on clients, dispatch, or profile
		// eslint-disable-next-line
	}, [init, profile.api, clients?.status]);

	// Clients fetch retry handler
	const handleRetry = () => {
		setError({ title: "", message: "" });
		dispatch(fetchClients({ api: profile.api, type: "private" }));
	};

	// Handle Whitelabel
	React.useEffect(() => {
		// Check to see if the profile meta is part of a management group
		if (profile?.meta?.manage) {
			let { creator, groupId } = profile.meta.manage;

			// Init a dbString to fill if the account belongs to a group
			let dbString = "";

			// Get the groupId and create a dbString if it's available
			if (!groupId && profile.meta.manage?.groupMember) {
				groupId = profile.meta.manage?.groupMember[0]?.groupId;
				if (groupId) {
					dbString = `users/${creator}/manage/groups/${groupId}/whitelabel`;
				}
			}

			if (Boolean(dbString)) {
				// If the dbString is valid, set the whitelabel, fetch it
				firebaseHook.ref(dbString).on("value", (snapshot: any) => {
					const data = snapshot.val();

					// If there's data, set the whitelabel
					if (data && data?.enabled) {
						setWhitelabel({
							...whitelabel,
							enabled: data.enabled,
							primary: data.primary || "",
							secondary: data.secondary || "",
							logoURL: data.logoURL || "",
							padding: data.padding || "",
						});
					}
				});
			}
		}
		// eslint-disable-next-line
	}, [profile.api]);

	// Loader handles both uninitialized and error states
	if (!init || error.title) {
		return (
			<IntroLoader
				title={"Welcome"}
				trigger={!init}
				error={error}
				retry={
					error && (
						<Button onClick={() => handleRetry()} variant="outlined">
							Reconnect
						</Button>
					)
				}
			/>
		);
	}

	// Construct theme
	let theme: Theme = createMuiTheme();

	// If whitelabel is enabled, proceed
	if (whitelabel.enabled) {
		// Set up default theme
		let palette: any = {
			primary: { main: indigo[500] },
			secondary: { main: indigo[500] },
		};

		// Adjust primary and secondary colors
		if (primary) {
			palette.primary.main = primary;
		}

		if (secondary) {
			palette.secondary.main = secondary;
		}

		// Output new palette
		theme = createMuiTheme({ palette });
	}

	// Render router
	return (
		<MuiThemeProvider theme={theme}>
			<div
				className={classes.app}
				style={{
					backgroundImage: `url(${Abstract1})`,
					height: size.height ? size.height : "1vh",
					minHeight: size.height ? size.height : "1vh",
				}}
			>
				<SnackbarProvider maxSnack={3}>
					<BrowserRouter>
						<Switch>
							<Route path="/iaq/:buildingId?/:deviceId?">
								<IAQPublicPage />
							</Route>

							{/* Redirect from root to /dashboard, if not authenticated will automatically redirect to /login */}
							<Redirect exact from="/" to="/iaq" />
							<Redirect exact from="/home" to="/iaq" />

							<Route path="/login">
								<Login />
							</Route>
						</Switch>
					</BrowserRouter>
				</SnackbarProvider>
			</div>
		</MuiThemeProvider>
	);
}

export default App;
