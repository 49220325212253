import React, { useState, useEffect } from "react";

// Firebase
import { functions } from "../firebase/firebase";

const fetchEndpointPublic = functions.httpsCallable("fetchEndpointPublic");

interface IIntervalExample {
	deviceData: any;
	route: string;
}

const usePollDevice = ({ deviceData, route }: IIntervalExample) => {
	const [pollingData, setPollingData]: any = useState(null);

	//! Update every 10 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			if (Boolean(deviceData) && Boolean(deviceData?.points?.length)) {
				fetchEndpointPublic({ route })
					.then((res: any) => {
						if (res?.data) {
							setPollingData(JSON.parse(res.data));
						}
					})
					.catch((e: any) => {
						console.log(e);
					});
			}
		}, 10000);

		return () => {
			setPollingData(null);
			clearInterval(interval);
		};
	}, [Boolean(deviceData)]);
	//! Update every 10 seconds

	if (!deviceData) return [];

	return [pollingData];
};

export default usePollDevice;
