import * as React from "react";

// MUI
import { LinearProgress } from "@material-ui/core";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchPublic } from "../../features/public/publicSlice";

// Components
import IAQClientTable from "./IAQClientTable";
import { IAQDeviceDisplay } from "./IAQDeviceDisplay";

interface IIAQPublicPageProps {}

export const IAQPublicPage: React.FC<IIAQPublicPageProps> = (props) => {
	// Redux
	const dispatch = useDispatch();
	const params: any = useParams();

	// Store
	const authStore = useSelector((state: any) => state.firebase.auth);
	const publicStore = useSelector((state: any) => state.public);

	const clientStore = publicStore.clients;
	const buildingStore = publicStore.buildings;

	// Convenience
	const hasClients = Boolean(Object.keys(clientStore).length);

	// Get clients and store in public
	React.useEffect(() => {
		if (!hasClients && publicStore.status !== "loading" && authStore.isLoaded) {
			dispatch(fetchPublic({ type: "clients" }));
		}
		// eslint-disable-next-line
	}, [authStore]);

	// Get buildings
	React.useEffect(() => {
		if (hasClients && publicStore.status !== "loading" && !params.deviceId) {
			let buildingIds: string[] = [];

			// Map down through to get building ids
			Object.keys(clientStore).forEach((client: any) => {
				if (clientStore[client].sites?.length) {
					clientStore[client].sites.forEach((site: any) => {
						if (site.buildings?.length) {
							site.buildings.forEach((building: any) => {
								buildingIds.push(building.id);
							});
						}
					});
				}
			});

			// Make sure building ids are unique
			let uniqIds = buildingIds.filter((id: string, idx: number) => {
				return Boolean(buildingIds.indexOf(id) === idx);
			});

			// Call resource and store buildings
			uniqIds.forEach((id: string) => {
				dispatch(fetchPublic({ type: "buildings", id }));
			});
		}
		// eslint-disable-next-line
	}, [Object.keys(clientStore).length]);

	if (Boolean(params.buildingId && params.deviceId)) {
		return <IAQDeviceDisplay />;
	}

	if (hasClients) {
		return (
			<IAQClientTable
				clients={JSON.parse(JSON.stringify(clientStore))}
				buildings={JSON.parse(JSON.stringify(buildingStore))}
			/>
		);
	}

	return <LinearProgress />;
};
