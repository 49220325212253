import React from "react";

import {
	AppBar,
	Button,
	Dialog,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Toolbar,
	Typography,
	makeStyles,
	ListItemSecondaryAction,
} from "@material-ui/core";

// Icons
import { Close } from "@material-ui/icons";

// React
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function IAQBuildingDialog({ building, open }: any) {
	const classes = useStyles();

	const history = useHistory();

	let [buildingDevices, setBuildingDevices] = React.useState([]);

	const handleClose = () => {
		history.push(`/iaq`);
	};

	React.useEffect(() => {
		if (open) {
			let devices: any = [];
			building.floors?.forEach((floor: any) =>
				floor.spaces?.forEach((space: any) =>
					space.devices?.forEach((device: any) => {
						if (device?.virtualProfile === "virtualAirQuality") {
							devices.push(device);
						}
					})
				)
			);

			setBuildingDevices(devices);
		}
		// eslint-disable-next-line
	}, [open]);

	return (
		<div>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							{building?.name}
						</Typography>
						<span style={{ display: "flex", flexGrow: 1 }} />
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<Close />
						</IconButton>
					</Toolbar>
				</AppBar>
				{building?.floors.map((floor: any) => (
					<List key={floor.id}>
						<ListItem>
							<ListItemText primary={floor.name} secondary={"Floor"} />
						</ListItem>
						<List component="div" disablePadding>
							{buildingDevices.map((device: any) => (
								<ListItem
									key={device.id}
									className={classes.nested}
									button
									onClick={() =>
										history.push({
											pathname: `/iaq/${building.id}/${device.id}`,
										})
									}
								>
									<ListItemText primary={device.name} secondary={`Device`} />
									<ListItemSecondaryAction>
										<div style={{ display: "flex", flexWrap: "wrap" }}>
											<Button
												variant="contained"
												color="default"
												style={{ marginRight: "1rem" }}
												onClick={() =>
													history.push({
														pathname: `/iaq/${building.id}/${device.id}`,
														search: "?dialOnly=1",
													})
												}
											>
												Dial Only
											</Button>
											<Button
												variant="contained"
												color="primary"
												onClick={() =>
													history.push({
														pathname: `/iaq/${building.id}/${device.id}`,
													})
												}
											>
												All Points
											</Button>
										</div>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
						<Divider />
					</List>
				))}
			</Dialog>
		</div>
	);
}
